<template>
  <v-container>
    <v-row>
      <v-col>
        <v-treeview
          v-model="valgte"
          :items="items"
          selection-type="independent"
          selectable
          return-object
          open-all
        ></v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-6" cols="6">
        <template v-if="!valgte.length"> Ingen valgt enda </template>
        <template v-else>
          <div v-for="node in valgte" :key="node.id">
            {{ node.name }}
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import { tr } from "date-fns/locale";

export default {
  name: "dlgSelectTeam",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valgte: [],
      valgteCopy: [],
      items: [],
    };
  },
  mounted() {
    this.valgteCopy = this.value;
    this.init("mounted");
  },
  created() {
    this.GetData();
  },

  methods: {
    init(fra) {
      this.valgte = this.value;
    },
    GetData() {
      axios({
        method: "get",
        url: DataSourceURL + "/PartnerTree/GetPartnerData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.items = res.data.DataList;
            this.valgte = this.valgteCopy; //Triks i ludo - siden data blir spist a tree view
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /PartnerTree/GetPartnerData'. See details: ${err}`
          );
        });
    },
  },
  watch: {
    // value: function() {
    //   this.init("watch");
    // },
    valgte: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", this.valgte);
      }
    },
  },
};
</script>
